import { render, staticRenderFns } from "./TextCategoryHighlight.vue?vue&type=template&id=4a4534ff&scoped=true&"
import script from "./TextCategoryHighlight.vue?vue&type=script&lang=ts&"
export * from "./TextCategoryHighlight.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4534ff",
  null
  
)

export default component.exports