





import {
    defineComponent,
    ref,
    onMounted,
    useFetch
} from "@nuxtjs/composition-api";
import { useContent } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";
import ContentBlock from "./ContentBlock.vue";

export default defineComponent({
    name: "TextCategoryHighlight",
    components: {
        ContentBlock,
    },
    setup() {
        const { loadBlocks } = useContent();
        const blocks = ref<CmsBlock[]>([]);
        const blockContent = ref();

        const { fetch, fetchState } = useFetch(async () => {
            blocks.value = await loadBlocks({ identifiers: ['text-category-highlight'] });


            const re = /<div[^>]*class="pagebuilder-column"[^>]*>.*?<\/div><\/div>/gs;
            const str = blocks?.value?.[0]?.content;
            let match;
            let i = 0;
            let tabContent = [];

            while ((match = re.exec(str)) !== null) {
                blockContent.value = match?.[0];
            }
        });
        fetch();
        return {
            blocks,
            blockContent
        };
    },
});
